.container {
  height: 100vh;
  padding-top: 90px;
}

.interior-hero {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(4, 4, 40, 0.6)),
    url("https://res.cloudinary.com/dksyt7yvf/image/upload/v1610983004/douglas-sheppard-9rYfG8sWRVo-unsplash_1_hmtc1h.png");
  height: 55vh;
  background-position: top;
  /* background-size: cover; */
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.interior-hero h1 {
  font-weight: 900;
  font-size: 64px;
  line-height: 80px;
  color: #fff;
}
.interior-hero h2 {
  font-weight: 500;
  font-size: 14px;
  line-height: 22.5px;
  width: 35%;
  text-align: center;
  color: #fff;
}
@media screen and (max-width: 576px) {
  .interior-hero h1 {
    font-weight: 900;
    font-size: 24px;
    line-height: 19px;
    color: #fff;
  }
  .interior-hero h2 {
    font-weight: 500;
    font-size: 14px;
    line-height: 22.5px;
    width: 35%;
    text-align: center;
    color: #fff;
  }
  .interior-hero h2 {
    width: 80%;
  }
}
