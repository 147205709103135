.container {
  height: 100vh;
  padding-top: 90px;
  overflow: scroll;
}

.residential-hero {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(4, 4, 40, 0.5)),
    url("https://res.cloudinary.com/codeinstd/image/upload/v1612784467/Hero_section_lftyav.jpg");
  height: 50vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.residential-hero h1 {
  font-weight: 900;
  font-size: 64px;
  line-height: 80px;
  color: #fff;
}
.residential-hero h2 {
  font-weight: 500;
  font-size: 14px;
  line-height: 22.5px;
  width: 55%;
  text-align: center;
  color: #fff;
}

.project__body__img img {
  object-fit: contain;
}

@media screen and (max-width: 576px) {
  .residential-hero {
    padding: 0 20px;
  }
  .residential-hero h1 {
    font-weight: 900;
    font-size: 24px;
    line-height: 19px;
    color: #fff;
  }
  .residential-hero h2 {
    font-weight: 500;
    font-size: 14px;
    line-height: 22.5px;
    width: 35%;
    text-align: center;
    color: #fff;
  }
  .residential-hero h2 {
    width: 100%;
  }
}

.modal_container {
  /* position: absolute; */
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.stop_scroll {
  /* overflow: hidden; */
  position: fixed;
}
