.container {
  /* height: 100vh; */
  /* padding-top: 90px; */
}

.real-estate-hero {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("https://res.cloudinary.com/codeinstd/image/upload/v1612608575/hero_section_1_ueruhm.jpg");
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.real-estate-hero h1 {
  font-weight: 900;
  font-size: 64px;
  line-height: 80px;
  color: #fff;
}

.real-estate-hero p {
  color: #fff;
  font-weight: 600;
  font-size: 36px;
  line-height: 57.6px;
}

@media (max-width: 1000px) {
  .real-estate-hero {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url("https://res.cloudinary.com/codeinstd/image/upload/v1610463771/image_11_sy1kes.png ");
    height: 70%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .real-estate-hero h1 {
    font-weight: 900;
    font-size: 40px;
    line-height: 50px;
    color: #fff;
  }

  .real-estate-hero p {
    color: #fff;
    font-weight: 600;
    font-size: 30px;
    line-height: 10px;
  }
}
