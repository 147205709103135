.App {
  text-align: center;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: white;
}

ul {
  list-style-type: none;
}

*:focus {
  outline: none;
}

.header {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* background-color: #fff; */
  background-color: rgba(255, 255, 255, 0.9);
  filter: blur(-100px);
}

.brand {
}

.brand-logo {
  width: 158px;
  height: 50px;
}

@media (max-width: 1000px) {
  .brand-logo {
    margin-left: 30px;
    width: 100px;
    height: 50px;
    object-fit: contain;
  }
  .header {
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.2);
    filter: blur(-50px);
    /* background-color: rgba(0, 0, 0, 0.5);
    filter: blur(20px); */
  }
  .header-button {
    border: none;
    outline: none;
    margin-right: 30px;
    border: 0;
    background: none;
    font-size: 2rem;
    cursor: pointer;
    color: black;
    transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
  }

  .header-button:focus {
    box-shadow: none;
  }

  .header-button:active {
    transform: scale(1.2);
  }
}

.header-links {
  display: flex;
  align-items: flex-start;
  padding-top: 25px;
}

.dropdown-link {
  font-weight: 500;
  font-size: 14px;
  padding-right: 1.5rem;
  color: #040428;
  /* background-color: red; */
  transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.dropdown-link:hover {
  color: #d4a70c;
  transform: translateY(-0.5px);
}

.dropdown {
  margin: 0;
  padding-left: 0;
}

.dropdown .dropdown-button {
  background-color: inherit;
  border: none;
  font-weight: bold;
  font-size: 14px;
  color: #040428;
  margin: 0;
  padding: 0;
  padding-right: 1.5rem;
  transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.dropdown-icon {
  padding-left: 5px;
  font-size: 12px;
}

.dropdown-button:hover {
  cursor: pointer;
  color: #d4a70c;
}

.dropdown:hover .dropdown-content {
  /* display: flex;
  /* flex-direction: column; */
}

.dropdown-content {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  position: absolute;
  text-align: left;
  background-color: #f9f9f9;
  padding: 0;
  opacity: 0;
  border-radius: 10px;
  overflow: hidden;
  z-index: 200;
}
.dropdown-contentAnimate {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  position: absolute;
  text-align: left;
  background-color: #f9f9f9;
  padding: 0;
  border-radius: 10px;
  overflow: hidden;
  z-index: 200;
  opacity: 0.9;
  animation-name: opac;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
}

#pm {
  height: 40px;
  margin-bottom: 10px;
}

#pm:hover .dropdown-contentAnimatePm {
  display: flex;
}

.dropdown-contentAnimatePm {
  display: none;
  padding-left: 0;
  padding: 8px 5px;
  position: absolute;
  top: 60px;
  flex-direction: column;
  text-align: left;
  background-color: #f9f9f9;
  padding: 0;
  border-radius: 10px;
  overflow: hidden;
  z-index: 200;
  opacity: 0.9;
  animation-name: opac;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
}

@keyframes (opac) {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}

.dropdown-contentAnimatePm a {
  /* display: block; */
  display: flex;
  font-weight: 500;
  font-size: 14px;
  padding: 1rem 0.3rem;
  text-align: left;
  color: #040428;
  border-bottom: 0.5px solid gray;
  transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.dropdown a {
  display: block;
  font-weight: 500;
  font-size: 14px;
  padding: 1rem;
  padding-top: 10px;
  color: #040428;
  border-bottom: 0.5px solid gray;
  transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.dropdown a:hover {
  background-color: darkgray;
  color: black;
}

/* sidebar */
.sidebar {
  position: fixed;
  z-index: 10;
  right: 0;
  width: 60%;
  /* background-color: #d4a70c; */
  background-color: rgba(255, 255, 255, 0.95);
  filter: blur(-20px);
  height: 100%;
  transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
  transform: translateX(100%);
}

@media (max-width: 350px) {
  .sidebar {
    width: 70%;
  }
}

.slide-menu {
  padding-top: 5px;
  padding-left: 30px;
}

.slide-innerMenu {
  margin-top: -25%;
  margin-left: 20px;
  padding-top: 0;
  padding-left: 0;
}
.slide-innerMenuAnimate {
  margin-top: -25%;
  margin-left: 20px;
  padding-top: 0;
  padding-left: 0;
  animation: slide;
  animation-duration: 5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}

@keyframes slide {
  from {
    transform: translateY(-20px;);
  }
  to {
    transform: translateY(0);
  }
}

.slide-innerList {
  margin: 0;
  padding: 0;
}

.slide-innerMenu li:first-child {
  margin-top: 6vh;
}

.sidebar.open {
  transform: translateX(0);
}

.sidebar a {
  color: #000;
  font-weight: 600;
  margin-left: 20px;
  transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
  text-align: left;
  font-size: 14px;
}

.sidebar a:hover {
  color: #000;
  transform: scale(2);
}

.sidebar li {
  margin: 2rem 0;
  color: white;
  text-align: left;
}

.sidebar li:first-child {
  margin-top: 12vh;
}

.sidebar li:hover .sidebar-icon {
  color: #000;
}

.sidebar-icon {
  color: #d4a70c;
  transition: 1s cubic-bezier(0.8, 0.8, 0.2, 1);
}

/* .main-div{
  margin-bottom: 5
} */

.home-hr {
  border: none;
  border-bottom: 0.5px solid lightgray;
}

.footer {
  display: flex;
  flex-direction: row;
  padding: 30px 150px 30px;
  justify-content: space-between;
  align-items: center;
}

.footer img {
  height: 50px;
  object-fit: contain;
}

.footer-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.footer-group {
  margin-right: 50px;
}

.footer-contact-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 10px;
}
.footer-contact-group a {
  font-size: 14px;
  color: #000;
  font-weight: 500;
}
.footer-contact-group p {
  font-size: 14px;
  margin: 0;
  font-weight: 500;
}

.footer-social-group p {
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: 500;
}

.footer-icon {
  padding-right: 10px;
}

.footer-icon-social {
  color: #fff;
  transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
  /* padding-right: 10px; */
}

.footer-social-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer-social-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.footer-social-icon-container:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
}

.footer-social-icon-container:hover .footer-icon-social {
  color: #d4a70c;
}

@media (max-width: 1000px) {
  .footer {
    padding: 10px 20px 20px;
    flex-direction: column;
  }

  .footer-links {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer img {
    height: 40px;
    margin-bottom: 10px;
  }

  .footer-group {
    margin-right: 0;
    margin-left: -20px;

    /* width: 30px; */
  }

  .footer-contact-group {
    width: 180px;
  }

  .footer-contact-group a {
    display: inline-block;
    /* width: 25px; */

    font-size: 14px;
    font-weight: 500;
  }

  .footer-contact-group p {
    font-size: 14px;
    margin: 0;
    font-weight: 500;
  }

  .footer-social-group {
    padding-top: 10px;
  }

  .footer-social-group p {
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 10px;
  }

  .footer-icon {
    padding-right: 10px;
    font-size: 16px;
  }

  .footer-icon-social {
    font-size: 18px;
  }

  .footer-social-icon-container {
    height: 40px;
    width: 40px;
    border-radius: 40px;
    margin: 0 5px;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
