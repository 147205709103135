.container {
  height: 100vh;
  padding-top: 70px;
  overflow: scroll;
}

.commercial-hero {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("https://res.cloudinary.com/codeinstd/image/upload/v1612783687/IMage_1_dutssh.jpg");
  height: 50vh;
  width: 100vw;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.commercial-hero h1 {
  font-weight: 900;
  font-size: 64px;
  line-height: 80px;
  color: #fff;
}
.commercial-hero h2 {
  font-weight: 500;
  font-size: 14px;
  line-height: 22.5px;
  width: 35%;
  text-align: center;
  color: #fff;
}
.project__body {
  margin: 8rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
}
.project__body__img {
  width: 100%;
  height: 235px;
}
.project__body__img img {
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.project__body__card {
  width: 310px;
  box-sizing: border-box;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.045);
  border-radius: 10px;
  overflow: hidden;
  transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.project__body__card:hover {
  cursor: pointer;
  transform: translateY(-1px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}
.project__body__text h2 {
  text-align: center;
  font-weight: 600;
  padding: 1em;
  font-size: 18px;
}

@media screen and (max-width: 576px) {
  .commercial-hero {
    /* padding: 0 20px; */
  }
  .commercial-hero h1 {
    font-weight: 900;
    font-size: 24px;
    line-height: 19px;
    color: #fff;
  }
  .commercial-hero h2 {
    font-weight: 500;
    font-size: 14px;
    line-height: 22.5px;
    width: 80%;
    text-align: center;
    color: #fff;
  }
  /* .commercial-hero h2 {
    width: 100%;
  } */
  .project__body {
    margin: auto;
    margin-top: 4rem;
    padding: 4%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }
  .project__body__card {
    width: auto;
  }
}
