.container {
  height: 100vh;
  width: 100vw;
  padding-top: 90px;
}

.contact-info {
  width: 100vw;
  display: flex;
  flex-direction: row;
  margin-bottom: 70px;
}

.contact-map {
  width: 60%;
  height: 586px;
  margin-right: 0;
}

.map-container {
  width: 55%;
  height: 586px;
  margin: 0 auto;
}

.contact-map img {
  width: 100%;
  height: 100%;
}

.contact-container {
  background-color: #040428;
  color: #fff;
  width: 45%;
  height: 586px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 80px;
}

.contact-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 40px;
}

.contact-group h2 {
  margin-top: 0;
  margin-bottom: 0;
}

.contact-icon {
  width: 28px;
  height: 28px;
  margin-right: 20px;
}

.contact-contact {
  display: flex;
  flex-direction: column;
  text-align: left;
}

@media (max-width: 1000px) {
  .contact-container {
    background-color: #040428;
    color: #fff;
    width: 45%;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 80px;
  }

  .contact-contact {
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  .contact-contact p {
    font-size: 12px;
    margin-bottom: 5px;
  }
}

.contact-form {
  /* height: 200px; */
  margin-bottom: 50px;
}

.contact-input-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.contact-input-group {
  display: flex;
  flex-direction: column;
}

.form-input {
  width: 264px;
  height: 45px;
  background-color: rgba(1, 1, 17, 0.03);
  border-radius: 20px;
  padding-left: 20px;
  margin: 20px;
  border: none;
}

.contact-textarea {
  width: 264px;
  height: 122px;
  background: rgba(1, 1, 17, 0.03);
  opacity: 0.5;
  padding: 10px 20px 5px;
  border-radius: 20px;
  margin: 20px;

  border: none;
}

.contact-button {
  margin-top: 20px;
  padding: 21px 27px;
  border-radius: 40px;
  background-color: #d4a70c;
  box-shadow: 0 10px 20px rgba(245, 166, 35, 0.4);
  transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
  color: #fff;
  z-index: 300;
  border: none;
  font-size: 14px;
  font-weight: bold;
}

.contact-button:hover {
  cursor: pointer;
  background-color: #000;
  transform: translateY(-3px);
  /* box-shadow: 0 10px 20px rgba(245, 166, 35, 0.4); */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
}

@media (max-width: 1000px) {
  .contact-get {
    font-size: 24px;
  }
  .contact-input-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .contact-info {
    width: 100vw;
    display: flex;
    flex-direction: column;
    margin-bottom: 70px;
  }

  .contact-map {
    width: 100%;
    height: 300px;
    margin-right: 0;
  }

  .map-container {
    width: 100%;
    height: 300px;
    margin: 0 auto;
  }

  .contact-container {
    padding-top: 10px;
    background-color: #040428;
    color: #fff;
    width: 100vw;
    height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding-left: 0;
  }
  .contact-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 40px;
    margin-left: 5%;
  }

  .contact-input-group {
    display: flex;
    flex-direction: column;
    width: 100vw;
    align-items: center;
  }

  .form-input {
    width: 70%;
    height: 45px;
    background-color: rgba(1, 1, 17, 0.03);
    border-radius: 20px;
    padding-left: 20px;
    margin: 20px;
    border: none;
  }

  .contact-textarea {
    width: 65%;
    height: 122px;
    background: rgba(1, 1, 17, 0.03);
    opacity: 0.5;
    padding: 10px 20px 5px;
    border-radius: 20px;
    margin: 20px;

    border: none;
  }

  .contact-button {
    margin-top: 20px;
    padding: 10px 20px;
    border-radius: 40px;
    background-color: #d4a70c;
    box-shadow: 0 10px 20px rgba(245, 166, 35, 0.4);
    transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
    color: #fff;
    z-index: 300;
    border: none;
    font-size: 14px;
    font-weight: bold;
  }
}
