.container {
  height: 100vh;
  padding-top: 90px;
}

.infastructural-hero {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(4, 4, 40, 0.5)),
    url("https://res.cloudinary.com/codeinstd/image/upload/v1612608676/hero_section_2_nrmnua.jpg");
  height: 55vh;
  background-position: top;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.infastructural-hero h1 {
  font-weight: 900;
  font-size: 64px;
  line-height: 80px;
  color: #fff;
}
.infastructural-hero h2 {
  font-weight: 500;
  font-size: 16px;
  line-height: 22.5px;
  width: 40%;
  text-align: center;
  color: #fff;
}

.project__body__img img {
  object-fit: cover;
}
@media screen and (max-width: 576px) {
  .infastructural-hero h1 {
    font-weight: 900;
    font-size: 24px;
    line-height: 19px;
    color: #fff;
  }
  .infastructural-hero h2 {
    font-weight: 500;
    font-size: 14px;
    line-height: 22.5px;
    width: 35%;
    text-align: center;
    color: #fff;
  }
  .infastructural-hero h2 {
    width: 90%;
  }
}
